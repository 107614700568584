<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <template>
      <v-card class="md-auto mb-5">
        <!-- <v-toolbar>
          <v-toolbar-title>School District: {{ user.schoolDistrict }}    >    School: {{ user.school }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title class="mx-5">Total Students Licences: {{ School.StudentLicencesAssigned }}</v-toolbar-title>
          <v-toolbar-title class="mx-5">Licences Available: {{ School.AvailableStudentLicences }}</v-toolbar-title>
          <v-btn color="primary" dark class="mb-2" @click="dialogStudent = true">
            New Student
          </v-btn>
        </v-toolbar> -->
        <v-row>
          <v-col cols="12" md="12" lg="12">            
            <v-row>
              <v-col xs="6" sm="6" md="3" lg="3">
                <v-row>
                  <v-col cols="5 my-lg-auto text-center">
                    <p class="text-h6 mb-1">
                      <v-icon>mdi-account-supervisor-circle</v-icon>Country
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <v-select
                      v-model="country"
                      outlined
                      :items="countries"
                      item-text="name"
                      menu-props="auto"
                      label="Select Country"
                      hide-details
                      return-object
                      single-line
                      @change="SelectCountry"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col xs="6" sm="6" md="3" lg="3">
                <v-row>
                  <v-col cols="5 my-lg-auto text-center">
                    <p class="text-h6 mb-1">
                      <v-icon>mdi-account-supervisor-circle</v-icon>State
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <v-select
                      v-model="state"
                      outlined
                      :items="states"
                      item-text="name"
                      menu-props="auto"
                      label="Select State"
                      hide-details
                      return-object
                      single-line
                      @change="SelectState"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col xs="6" sm="6" md="3" lg="3">
                <v-row>
                  <v-col cols="5 my-lg-auto text-center">
                    <p class="text-h6 mb-1">
                      <v-icon>mdi-account-supervisor-circle</v-icon>School Districts
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <v-select
                      v-model="schoolDistrict"
                      outlined
                      :items="schoolDistricts"
                      item-text="name"
                      menu-props="auto"
                      label="Select Districts"
                      hide-details
                      return-object
                      single-line
                      @change="SelectSchoolDistrict"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col xs="6" sm="6" md="3" lg="3">
                <v-row>
                  <v-col cols="5 my-lg-auto text-center">
                    <p class="text-h6 mb-1">
                      <v-icon>mdi-account-supervisor-circle</v-icon>Schools
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <!-- <v-select
                      v-model="school"
                      outlined
                      :items="schoolsList"
                      item-text="name"
                      menu-props="auto"
                      label="Select School"
                      hide-details
                      return-object
                      single-line
                      @change="ChooseSchool"
                    ></v-select> -->
                    <v-autocomplete
                  :items="schoolsList" item-text="name" class="mx-2" return-object label="School" solo
                  v-model="school" @change="ChooseSchool"
                ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        <v-row>
        </v-row>
          <v-col cols="12" md="2" lg="2">
            <v-btn color="primary" dark class="mb-2" @click="dialogStudent = true" :disabled="!HaveLicences">
              New Student
            </v-btn>
          </v-col>
          <!-- <v-col>
            <v-icon small class="ml-4" color="blue accent-4" @click="createReportAllTeachers()" >
                mdi-file-pdf-box
              </v-icon> 
          </v-col> -->
          <v-col cols="12" md="2" lg="2" v-if="school!=''">
            <v-btn color="primary" dark class="mb-2" @click="ShowReportGameProgress()">
              Show Report
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <!-- <v-card class="my-5">
        <v-toolbar flat color="cyan lighten-5">
          <v-toolbar-title>
            Students created for clever but not initialized
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <template v-for="(element, index) in PendingStudents">
          <v-chip class="ma-2" color="cyan" label text-color="white" :key="index">
            <v-icon left>
              mdi-account
            </v-icon>
            {{ element.email }}
          </v-chip>
        </template>
      </v-card> -->

      <v-data-table :headers="tableHeader" :items="students" sort-by="name" class="elevation-1" id="downloadexcel" >
        <template v-slot:top>
          <v-row>
            <v-col cols="2">
              <p class="text-h5 text-center"> Students</p>
            </v-col>
            <v-col cols="3">
              <v-select :items="teachersList" v-model="teacherFilterValue" label="Teacher" outlined></v-select>
            </v-col>
            <v-col cols="3">
              <v-select :items="gradosList" v-model="gradoFilterValue" label="Grade" outlined></v-select>
            </v-col>
            <v-col>
              <v-btn color="success" elevation="2" @click="ExportExcel('xls')">Export <v-icon right dark>mdi-file-excel</v-icon></v-btn>
            </v-col>
            <v-col>Total Usage time: {{ TotalStudentsTimeUsage }} </v-col>
          </v-row>
          <v-dialog v-model="dialogStudent" max-width="1000px">
              <v-card>
                <v-form ref="form" v-model="valid"  @submit.prevent="save" lazy-validation>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="student.name" outlined :rules="nameRules" label="Name" prepend-icon="mdi-account" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="student.lastName" outlined :rules="nameRules" label="Last Name" prepend-icon="mdi-account" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu v-model="dateOfBirth" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="student.dateOfBirth" outlined label="Date of Birth (optional)" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="student.dateOfBirth" @input="dateOfBirth = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="student.email" outlined :rules="emailRules" label="Email" prepend-icon="mdi-email"></v-text-field>
                      </v-col>
                      <!-- <v-col class="d-flex" cols="12" sm="6" md="6">
                        <v-select :items="['Clever','Email and Google']" prepend-icon="mdi-apple-keyboard-option" v-model="student.typeLogin" outlined label="Select Type Login"></v-select>
                      </v-col> -->
                      <v-col cols="12" sm="6" md="6" >
                        <!--<v-text-field v-model="student.studentId" outlined label="Student UID" prepend-icon="mdi-barcode" @keydown="changeStudentId"></v-text-field>-->
                        <v-text-field v-model="student.studentId" outlined label="Password" prepend-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" @keydown="changeStudentId"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" >
                        <!--<v-text-field v-model="student.password" outlined prepend-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="(editedUID === -1)?[passwordRules.required, passwordRules.min]:[]" :type="showPassword ? 'text' : 'password'" label="Password" counter @click:append="showPassword = !showPassword" v-if="!useIdPassword"></v-text-field>
                        <p v-else> you are using student code as password, press if you want to use a different password <v-btn class="ma-2" outlined color="indigo" @click="changePassword">Change Password</v-btn></p>-->
                      </v-col>
                       <v-col cols="12" sm="6" md="6" v-if="sSchool.IsDemo">
                        <v-checkbox v-model="IsDemo" :class="IsDemo?'green--text-chk':'red--text-chk'" :label="`Demo user: ${IsDemo?'Active':'Inactive'}`"></v-checkbox>
                        <label class="grey--text mt-n5">Unlock all levels and all games for demonstrations.</label>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text type="submit">
                    Save
                  </v-btn>
                </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteUserConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteUser(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </template>
    <v-dialog v-model="dialogLoad" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait Please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTableGameProgress" max-width="90%">
      <v-card>
        <v-toolbar flat color="cyan lighten-3" dense>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="blue-grey lighten-1"
                @click="dialogTableGameProgress = false"
              >
                <v-icon left> mdi-window-close </v-icon>
                Close
              </v-btn>
            </v-toolbar>

        <v-btn class="ma-2" color="success" elevation="2" @click="createReportAllTeachers()">Export <v-icon right dark>mdi-file-excel</v-icon>
        </v-btn>
        <table id="tablaGameProgress">
          <caption>School</caption>
          <tr>
            <th style="font-weight: bold;">{{ school.name }} </th>
          </tr>
          <template v-for="(arrayClass,indexGeneral) in ArrayClassroomTable">
            <tr></tr>
            <tr v-if="arrayClass.count>0">
              <td style="font-weight: bold;background-color: #ebebeb;">Grade</td>
              <td style="background-color: #ebebeb;">{{ gradosList[indexGeneral+1].text }}</td>
              <td style="background-color: #ebebeb;">Student Game Progress Percentages</td>
              
            </tr>
            <template  v-if="arrayClass.count>0">
              <tr v-for="(porcentaje,index) in arrayClass.Alumnos">
                <td></td>
                <td >Mission {{ index+1 }} - {{ misionesGame[index] }}</td>
                <td>{{  porcentaje==0?'-':(porcentaje +' % - ') }} </td>
              </tr>
            </template>
          </template>
          <!-- <tr v-if="arrayCLassRoom[0].count>0">
            <td>Grade</td>
            <td>Pre-School</td>
            <td>Student Game Progress Percentages</td>
            
          </tr>
          <template  v-if="arrayCLassRoom[0].count>0">
            <tr v-for="(tipo,index) in arrayCLassRoom[0].Alumnos">
              <td></td>
              <td >Mission {{ index+1 }} - {{ misionesGame[index] }}</td>
              <td>{{  tipo==0?'-':((tipo*100)/arrayCLassRoom[0].count +' %') }} </td>
            </tr>
          </template>
          <tr></tr>
          <tr v-if="arrayCLassRoom[1].count>0">
            <td>Grade</td>
            <td>PK</td>
            <td>Student Game Progress Percentages</td>
            
          </tr>
          <template  v-if="arrayCLassRoom[1].count>0">
            <tr v-for="(tipo,index) in arrayCLassRoom[1].Alumnos">
              <td></td>
              <td >Mission {{ index+1 }} - {{ misionesGame[index] }}</td>
              <td>{{  tipo==0?'-':((tipo*100)/arrayCLassRoom[1].count +' %') }} </td>
            </tr>
          </template> -->
        </table>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { db, functions, auth } from '../../../plugins/firebase'
import firebase from 'firebase'
export default {
  data () {
    return {
      user: {},
      tableHeader: [
        { text: 'Name', value: 'name' },
        { text: 'Last Name', value: 'lastName' },
        //{ text: 'Email', value: 'email' },
        { text: 'Usage time', value: 'timeUse' },
        { text: 'Grade', value: 'className', filter: this.gradosFilter,},
        { text: 'Teacher', value: 'teacher', filter: this.teachersFilter},
        { text: 'Expired', value: 'expired'},
        //{ text: 'Enabled', value: 'enabled' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      students: [],
      student: {
        name: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        studentId: '',
        password: '',
        typeLogin: ''
      },
      nameRules: [
        v => (v && v.length >= 2) || 'Minimum length is 2 characters',
        v => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => (v || (Object.prototype.hasOwnProperty.call(v, 'length') && v.length >= 6)) || 'Min 6 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      clearStudent: {
        name: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        studentId: '',
        password: '',
        typeLogin: ''
      },
      editedUID: -1,
      dialogStudent: false,
      dialogDelete: false,
      dialogLoad: false,
      valid: true,
      alertError: '',
      alertSuccess: '',
      showPassword: true,
      useIdPassword: true,
      dateOfBirth: false,
      collection: 'Students',
      PendingStudents: [],
      countries: [],
      states: [],
      schoolDistricts: [],
      schoolsList: [],
      sSchool: {},
      country: '',
      state: '',
      schoolDistrict: '',
      schoolAdminID: '',
      school: '',
      gradoFilterValue: '',
      gradosList: [
        {text: "All", value: null},
        {text: "Pre-School", value: 'Pre-School'},
        {text: "Kindergarten", value: 'Kindergarten'},
        {text: '1st Grade', value: '1st Grade'},
        {text: '2nd Grade', value: '2nd Grade'},
        {text: '3rd Grade', value: '3rd Grade'},
        {text: '4th Grade', value: '4th Grade'},
        {text: '5th Grade', value: '5th Grade'},
        {text: '6th Grade', value: '6th Grade'},
        {text: '7th Grade', value: '7th Grade'},
        {text: '8th Grade', value: '8th Grade'},
        {text: '9th Grade', value: '9th Grade'},
        {text: '10th Grade', value: '10th Grade'},
        {text: '11th Grade', value: '11th Grade'}
      ],
      teachersList: [
         {text: "All", value: null},
      ],
      teacherFilterValue: '',
      IsDemo: false,
      TotalStudentsTimeUsage: 0,
      HaveLicences: false,
      AllStudents:0,
      arrayCLassRoom : [
        { count: 0, Alumnos:[0,0,0,0,0,0,0]},
        { count: 0, Alumnos:[0,0,0,0,0,0,0]},
        { count: 0, Alumnos:[0,0,0,0,0,0,0]},
        { count: 0, Alumnos:[0,0,0,0,0,0,0]},
        { count: 0, Alumnos:[0,0,0,0,0,0,0]},
        { count: 0, Alumnos:[0,0,0,0,0,0,0]},
        { count: 0, Alumnos:[0,0,0,0,0,0,0]},
        { count: 0, Alumnos:[0,0,0,0,0,0,0]},
      ],
      misionesGame: [
        'Los Sonidos','Las Sílabas','Palabras','Extensiones, sílabas inversas, sílabas con inicio','Tres Sílabas','Sílabas trabadas','Lectura de Oraciones'
      ],
      dialogTableGameProgress:false,
      ArrayClassroomTable: []
    }
  },
  created () {
    this.ListCountries();    
  },
  methods: {
    async ListCountries () {
      await db.collection('SchoolsData').doc('RegionSchoolD').get().then((doc) => {
        let Countries = doc.data().Countries;
        let ids = Object.keys(Countries);
        let newCountries = [];
        ids.forEach(element => {
          newCountries.push({ id: element, name: Countries[element] })
        });
        newCountries.sort((a, b) => a.name.localeCompare(b.name));
        this.countries = newCountries;
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectCountry () {
      this.dialogLoad = true;
      let doc = await db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).get();
      let ListStates = doc.data().States;
      let ids = Object.keys(ListStates);
      let newStates = [];
      ids.forEach(element => {
        newStates.push({ id: element, name: ListStates[element] })
      });
      newStates.sort((a, b) => a.name.localeCompare(b.name));
      this.states = newStates;
      // aqui reiniciamos los demas selects
      this.schoolDistricts = [];
      this.schoolsList = [];
      this.state = '';
      this.schoolDistrict = '';
      this.school = '';
      this.dialogLoad = false;
    },
    async SelectState () {
      this.dialogLoad = true;
      let SchoolDistrictReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id);
      let doc = await SchoolDistrictReference.get();
      let ListSchoolDistrict = doc.data().SchoolDistricts;
      let ids = Object.keys(ListSchoolDistrict);
      let newSchoolDistricts = [];
      ids.forEach(element => {
        newSchoolDistricts.push({ id: element, name: ListSchoolDistrict[element] })
      });
      newSchoolDistricts.sort((a, b) => a.name.localeCompare(b.name));
      this.schoolDistricts = newSchoolDistricts;
      // aqui reiniciamos los demas selects
      this.schoolsList = [];
      this.schoolDistrict = '';
      this.school = '';
      this.dialogLoad = false;
    },
    async SelectSchoolDistrict () {
      this.dialogLoad = true;
      let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id);
      let doc = await SchoolReference.get()
      let ListSchool = doc.data().Schools;
      let ids = Object.keys(ListSchool);      

      this.schoolsList = [];
      if (Object.prototype.hasOwnProperty.call(doc.data(), "Schools2")) {
        const schools2List=doc.data().Schools2;
        schools2List.sort((a, b) => a.name.localeCompare(b.name));
        schools2List.forEach(idVSchool => {
          db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id).collection("Schools").doc(idVSchool.id).get().then(
            (dataSc)=>{
              if (dataSc.data().LicencesRegistration > 0) {
                this.schoolsList.push({ id: idVSchool.id, name: idVSchool.name });
              }
            }
          );
          if (ids.includes(idVSchool)) {
            ids = ids.filter(id => id != idVSchool);
          }
        });
        
        this.schoolsList.unshift({ divider: true });
        this.schoolsList.unshift({ header: 'SCHOOL LIST 2' });
        this.schoolsList.unshift({ divider: true });       
      }      
      //Lo ordenamos
      ids.sort((a, b) => ListSchool[b].localeCompare(ListSchool[a]));
      ids.forEach(element => {
        this.schoolsList.unshift({ id: element, name: ListSchool[element] })
      });     

      this.school = '';
      this.dialogLoad = false;
    },
    async ChooseSchool() {
      let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id).collection('Schools').doc(this.school.id);
      this.user.Reference = SchoolReference;
      this.user.SchoolDistrict = this.schoolDistrict.name;
      this.user.School =this.school.name;
      this.GetStudentsTable();
      this.GetPendingUsers();
    },
    editUser (data) {
      this.editedUID = data.id;
      // this.student = Object.assign({}, data)
      this.GetDataUser().then((getUser) => {
        console.log(getUser);
        this.student.name = getUser.Name;
        this.student.lastName = getUser.LastName;
        this.student.dateOfBirth = getUser.DateOfBirth;
        this.student.email = getUser.Email;
        this.student.studentId = getUser.StudentId;
        this.IsDemo = getUser.IsDemo;
        if (this.IsDemo == undefined) {
          this.IsDemo = false;
        }
        this.useIdPassword = false;
      });
      this.dialogStudent = true;
    },
    deleteUser (data) {
      this.editedUID = data.id;
      this.student.studentId = data.studentId;
      this.student.classroomPath = data.classroomPath;
      this.student = Object.assign({}, data)
      this.dialogDelete = true
    },
    deleteUserConfirm () {
      let respuesta = this.deleteData(this.editedUID);
      console.log(respuesta);
      this.closeDelete()
      //location.reload();
    },
    close () {
      this.dialogStudent = false;
      this.useIdPassword = true;
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.student = Object.assign({}, this.clearStudent)
        this.editedUID = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.student = Object.assign({}, this.clearStudent)
        this.editedUID = -1
      })
    },
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.editedUID !== -1) {
        this.dialogLoad = true;console.log("this.student");console.log(this.student);
        this.updateData(this.student).then(response => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "update successfully ";
            let principal = this;
           
            this.GetPendingUsers();
            this.ChooseSchool();
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          } else {
            let principal = this;
            if (Object.prototype.hasOwnProperty.call(response.message, 'code')) {
              switch (response.message.code) {
                case 'not-found':
                  this.alertError = 'user not found';
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () { principal.alertError = '' }, 3000);
          }
          this.dialogLoad = false
        });
      } else {
        this.dialogLoad = true;
        this.createData(this.student).then(response => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "created successfully ";
            let principal = this;
   
            this.GetPendingUsers();
            this.ChooseSchool();
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false
        });
      }
      this.close()
      this.$refs.form.resetValidation()
    },
    validate () {
      this.$refs.form.validate()
    },
    // llamadas a firebase
    async createData (student) {
      let answer = { result: '', message: '' }
      try {
        student.collection = this.collection;
        student.schoolDistrict = this.user.SchoolDistrict;
        student.school = this.user.School;
        student.dateExpiration = this.user.PickerExpiration.toDate().getTime();
        student.IsDemo = this.IsDemo;
        student.reference = this.user.Reference.path;
        if (this.useIdPassword) {
          student.password = student.studentId;
        }
        console.log(student)
        const setAdmin = functions.httpsCallable('createStudent')
        await setAdmin(student)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.result = false;
        answer.message = error;
        // console.log(error);
      }
      return answer;
    },
    async existsUser (email) {
      let signInMethods = await auth().fetchSignInMethodsForEmail(email);
      if (signInMethods.length == 0) {
        let docVerify = await db.collection("PreUsersClever").doc(email).get();
        if (!docVerify.exists) {
          console.log('No existe!');
          return false;
        } else {
          console.log('si existe');
          return true
        }
              
      } else {
        console.log(signInMethods)
        console.log('si existe')
        return true;
      }
    },
    async updateData (student) {
      let answer = { result: '', message: '' }
      try {
        student.uid = this.editedUID;
        student.collection = this.collection;
        student.schoolDistrict = this.user.SchoolDistrict;
        student.reference = this.user.Reference.path;
        student.school = this.user.School;
        student.IsDemo = this.IsDemo;
        const updatetAdmin = functions.httpsCallable('updateStudent')
        await updatetAdmin(student)
        answer.result = true;
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log('entro al log general');
      }
      return answer;
    },
    async deleteData (id) {
      this.dialogLoad =true;
      try {
        const deleteStudent = functions.httpsCallable('deleteStudent');
        let res =await deleteStudent({id: id, path: this.user.Reference.path, studentId: this.student.studentId, classroomPath: this.student.classroomPath});
        console.log(res);
        this.dialogLoad =false;
        this.GetPendingUsers();
        this.ChooseSchool();
      } catch (error) {
        console.log(error)
        this.dialogLoad =false;
      }
    },
    async Users () {
      await db.collection(this.table).onSnapshot((snapshotChange) => {
        this.users = [];
        snapshotChange.forEach((doc) => {
          let oneStudent = doc.data();
          oneStudent.id = doc.id;
          this.users.push(oneStudent);
        });
      }, (error) => {
        console.log(error);
      })
    },
    async GetDataUser () {
      let getData = await db.collection('Students').doc(this.editedUID).get();
      return getData.data();
    },
    async GetStudentsTable(){
      this.dialogLoad =true;
      this.AllStudents = 0;
      // this.dialogLoad = true;
      let AllStudentsData = await this.user.Reference.collection('StudentsData').doc('P1').get();
      console.log(AllStudentsData.data());
      let studentsData = AllStudentsData.data().Students;
      let newStudents = [];
      let TotalTimeUse = 0;
      if (Object.keys(studentsData).length !== 0) {
        let ids = Object.keys(studentsData);
        // for (const element of ids) {
        ids.forEach(async element => {
          let timeUse = 0;
          let student = await db.collection('Students').doc(element).get();
          let TempAlumno = student.data().Alumno;
          if (Object.prototype.hasOwnProperty.call(student.data(), 'Alumno')) {
            if (TempAlumno.AprendizajeM1v2 !=null) {
              timeUse += TempAlumno.AprendizajeM1v2.TiempoTotalUso;
            }
            if (TempAlumno.AprendizajeM2v2 !=null) {
              timeUse += TempAlumno.AprendizajeM2v2.TiempoTotalUso;
            }
            if (TempAlumno.AprendizajeM3v2 !=null) {
              timeUse += TempAlumno.AprendizajeM3v2.TiempoTotalUso;
            }
            if (TempAlumno.AprendizajeM4v2 !=null) {
              timeUse += TempAlumno.AprendizajeM4v2.TiempoTotalUso;
            }
            if (TempAlumno.AprendizajeM5v2 !=null) {
              timeUse += TempAlumno.AprendizajeM5v2.TiempoTotalUso;
            }
            if (TempAlumno.AprendizajeM6v2 !=null) {
              timeUse += TempAlumno.AprendizajeM6v2.TiempoTotalUso;
            }
            if (TempAlumno.AprendizajeM7v2 !=null) {
              timeUse += TempAlumno.AprendizajeM7v2.TiempoTotalUso;
            }
          }
          //console.log(studentsData[element].userUIDSTeacher);
          let className = '';
          let teacherName ='';
          if (studentsData[element].UserUIDSTeacher != '') {
            let teacher = await db.collection('SchoolTeacher').doc(studentsData[element].UserUIDSTeacher).get();
            let classroom = teacher.data().Classrooms;
            teacherName = teacher.data().Name + ' ' + teacher.data().LastName;
            let clasesNames = Object.keys(classroom);
            for (const ClassroomSelected of clasesNames) {
             let TempClassData = await db.collection('SchoolTeacher').doc(studentsData[element].UserUIDSTeacher).collection('Classrooms').doc(ClassroomSelected).get();
             if (Object.prototype.hasOwnProperty.call(TempClassData.data().MainGroup, element)) {
                className = TempClassData.data().Grade;
              }
            }
            this.OrdenarReportePorcentaje(className,TempAlumno);
          }
          if (teacherName != '') {
            this.teachersList.push({ text: teacherName, value: teacherName });
          }
          TotalTimeUse += timeUse;
          newStudents.push({ id: element, name: studentsData[element].Name, lastName: studentsData[element].LastName, email: studentsData[element].Email , timeUse : this.secondsToString(Math.round( timeUse )), className: className, teacher: teacherName, classroomPath: student.data().ClassroomPath, studentId: student.data().StudentId, expired: student.data().Email })
          this.TotalStudentsTimeUsage = this.secondsToString(Math.round( TotalTimeUse ))
        });
      }
      let me =this;
      setTimeout(function () {
        me.dialogLoad = false;
    }, 5000);
      this.students = newStudents; 
    },
    GetPendingUsers (){
      this.PendingStudents = [];
      db.doc(this.user.Reference.path).get().then((doc) => {
        this.user.PickerExpiration = doc.data().DateExpired;
        this.sSchool = { AvailableStudentLicences: doc.data().AvailableStudentLicences , StudentLicencesAssigned: doc.data().StudentLicencesAssigned , IsDemo: doc.data().IsDemo }
        // verificamos si puede crear alumnos
      if (doc.data().ExactLicences == true) {
        if (doc.data().AvailableStudentLicences > 0) {
          this.HaveLicences = true;
        }else{
          this.HaveLicences = false;
        }
      }else{
        this.HaveLicences = true;
      }
      })
    },
    changeStudentId () {
      // console.log(this.student.studentId)
      if (this.useIdPassword) {
        this.student.password = this.student.studentId;
      }
    },
    changePassword () {
      this.useIdPassword = !this.useIdPassword;
    },
    secondsToString (seconds) {
      var hour = Math.floor(seconds / 3600);
      hour = (hour < 10)? '0' + hour : hour;
      var minute = Math.floor((seconds / 60) % 60);
      minute = (minute < 10)? '0' + minute : minute;
      var second = seconds % 60;
      second = (second < 10)? '0' + second : second;
      if (hour == '00') {
        return minute + ' m ' + second + ' s';
      }
      return hour + ' h ' + minute + ' m ' + second + ' s';
    },
    ExportExcel(){
      let dia = new Date();
      let table2excel = new Table2Excel();
      table2excel.export(document.getElementsByTagName("table")[0],"Grame Progress - Students "+dia.toLocaleDateString());
    },
    gradosFilter(value) {
        // If this filter has no value we just skip the entire filter.
        if (!this.gradoFilterValue) {
          return true;
        }

        // Check if the current loop value (The calories value)
        // equals to the selected value at the <v-select>.
        return value === this.gradoFilterValue;
      },
    teachersFilter(value){
        if (!this.teacherFilterValue) {
          return true;
        }

        // Check if the current loop value (The calories value)
        // equals to the selected value at the <v-select>.
        return value === this.teacherFilterValue;
    },
    OrdenarReportePorcentaje(Classroom, Alumno){
      this.AllStudents++;
      switch (Classroom) {
        case 'Pre-School':
          this.arrayCLassRoom[0].count +=1
          if (Alumno == undefined){
            this.arrayCLassRoom[0].Alumnos[0]++
          }else{
            this.arrayCLassRoom[0].Alumnos[Alumno.MisionActual-1]++
          }
          break;
        case 'Kindergarten':
          this.arrayCLassRoom[1].count +=1
          if (Alumno == undefined){
            this.arrayCLassRoom[1].Alumnos[0]++
          }else{
            this.arrayCLassRoom[1].Alumnos[Alumno.MisionActual-1]++
          }
          break;
        case '1st Grade':
          this.arrayCLassRoom[2].count +=1
          if (Alumno == undefined){
            this.arrayCLassRoom[2].Alumnos[0]++
          }else{
            this.arrayCLassRoom[2].Alumnos[Alumno.MisionActual-1]++
          }
          break;
        case '2nd Grade':
          this.arrayCLassRoom[3].count +=1
          if (Alumno == undefined){
            this.arrayCLassRoom[3].Alumnos[0]++
          }else{
            this.arrayCLassRoom[3].Alumnos[Alumno.MisionActual-1]++
          }
          break;
        case '3rd Grade':
          this.arrayCLassRoom[4].count +=1
          if (Alumno == undefined){
            this.arrayCLassRoom[4].Alumnos[0]++
          }else{
            this.arrayCLassRoom[4].Alumnos[Alumno.MisionActual-1]++
          }
          break;
        case '4th Grade':
          this.arrayCLassRoom[5].count +=1
          if (Alumno == undefined){
            this.arrayCLassRoom[5].Alumnos[0]++
          }else{
            this.arrayCLassRoom[5].Alumnos[Alumno.MisionActual-1]++
          }
          break;
        case '5th Grade':
          this.arrayCLassRoom[6].count +=1
          if (Alumno == undefined){
            this.arrayCLassRoom[6].Alumnos[0]++
          }else{
            this.arrayCLassRoom[6].Alumnos[Alumno.MisionActual-1]++
          }
          break;
        case '6th Grade':
          this.arrayCLassRoom[7].count +=1
          if (Alumno == undefined){
            this.arrayCLassRoom[7].Alumnos[0]++
          }else{
            this.arrayCLassRoom[7].Alumnos[Alumno.MisionActual-1]++
          }
          break;
        case '7th Grade':
          this.arrayCLassRoom[8].count +=1
          if (Alumno == undefined){
            this.arrayCLassRoom[8].Alumnos[0]++
          }else{
            this.arrayCLassRoom[8].Alumnos[Alumno.MisionActual-1]++
          }
          break;
        default:
          console.log(Alumno)
          break;
      }
    },
    ShowReportGameProgress(){
      this.dialogTableGameProgress=true;
      console.log(this.arrayCLassRoom)
      this.ArrayClassroomTable = structuredClone(this.arrayCLassRoom);
      for (let index = 0; index < this.arrayCLassRoom.length; index++) {
        let total = this.arrayCLassRoom[index].count;
        let ArrayData=this.arrayCLassRoom[index].Alumnos
        let arrayTemp =[]
        for (let indexInferior = 0; indexInferior < ArrayData.length; indexInferior++) {
          arrayTemp.push(Math.round((ArrayData[indexInferior]*100)/total))
        }
        this.$set(this.ArrayClassroomTable[index], 'Alumnos', arrayTemp)
      }
    },
    async createReportAllTeachers(){
      
      let table2excel = new Table2Excel();
      table2excel.export(document.getElementsByTagName("table")[1],"LeeLee Student Edition ");
    }
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New Student' : 'Edit Student'
    },
  },
  watch: {
    dialogStudent (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogLoad (val) {
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
<style>
  .green--text-chk .v-label {
    color: green !important;
  }

  .red--text-chk .v-label {
    color: red !important;
  }


#tablaGameProgress td{
	font-weight: normal;
	font-size: 1em;
	-webkit-box-shadow:0 2px 2px-2px #0E1119;
	-moz-box-shadow:0 2px 2px -2px #0E1119;
	box-shadow: 0 2px 2px -2px #5e6577;

}
#tablaGameProgress{
	text-align: left;
	overflow: hidden;
	width: 80%;
	margin: 0 auto;
	display: table;
	padding: 0 0 4em 0;

}
#tablaGameProgress td, #tablaGameProgress th{
	padding-bottom: 1%;
	padding-top: 1%;
	padding-left: 1%;

}
/*Background-color of the odd rows */
#tablaGameProgress tr:nth-child(odd){
	background-color: #f6f9ff;

}
/*Background-color of the even rows*/
#tablaGameProgress tr:nth-child(even){
	background-color: #f6f9ff;
}
#tablaGameProgress th{
	background-color: #b7b8bb;

}

#tablaGameProgress tr:hover{
	background-color: #a8a8a8;
	-webkit-box-shadow:0 6px 6px -6px #0E1119;
	-moz-box-shadow:0 6px 6px -6px #0E1119;
	box-shadow: 0 6px 6px -6px #0E1119;
}

</style>
